import { plugin, axios } from './plugins/axios';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import PortalVue from 'portal-vue';

import '@loophq/component-library/dist/style.css';
import '/node_modules/@loophq/ui/dist/style.css';

export const app = createApp(App)

app.use(plugin);
app.use(store);
app.use(router);
app.use(PortalVue);

app.mount('#app');

export default app;

export { axios }
