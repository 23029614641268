import { createRouter, createWebHistory } from "vue-router";
import { enablePageScroll } from "scroll-lock";

function loadView(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const router =  createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "startup",
      component: loadView("Startup"),
    },
    {
      path: "/login",
      name: "login",
      component: loadView("Login"),
    },
    {
      path: "/shops",
      name: "home",
      component: loadView("Home"),
    },
    {
      path: "/gdpr-data",
      name: "redactGDPRData",
      component: loadView("GDPRPage"),
    },
    {
      path: "/returns",
      name: "returns",
      component: loadView("Return"),
    },
    {
      path: "/returns/:id",
      component: loadView("ReturnDetail"),
      children: [
        {
          path: "",
          redirect: { name: "returnAccounting" }
        },
        {
          path: "accounting",
          name: "returnAccounting",
          component: loadView("ReturnAccounting"),
        },
        {
          path: "timeline",
          name: "returnTimeline",
          component: loadView("ReturnTimeline"),
        },
        {
          path: "raw",
          name: "returnRaw",
          component: loadView("ReturnRaw"),
        },
        {
          path: "settings",
          name: "returnSettings",
          component: loadView("ReturnSettings"),
        },
        {
          path: "formatted",
          name: "returnFormatted",
          component: loadView("ReturnFormatted"),
        },
        {
          path: "ledger",
          name: "returnLedger",
          component: loadView("ReturnLedger"),
        },
        {
          path: "logs",
          name: "returnLogs",
          component: loadView("ReturnLogs"),
        },
      ],
    },
    {
      path: "/orders/:id",
      component: loadView("OrderDetail"),
      children: [
        {
          path: "",
          redirect: { name: "orderAccounting" }
        },
        {
          path: "accounting",
          name: "orderAccounting",
          component: loadView("OrderAccounting"),
        },
        {
          path: "raw",
          name: "orderRaw",
          component: loadView("OrderRaw"),
        },
        {
          path: "formatted",
          name: "orderFormatted",
          component: loadView("OrderFormatted"),
        },
        {
          path: "workflow-logs",
          name: "orderWorkflowLogs",
          component: loadView("OrderWorkflowLogs"),
        },
      ],
    },
    {
      path: "/workflow-logs",
      name: "workflowLogs",
      component: loadView("WorkflowLogs"),
    },
    {
      path: "/shipping",
      name: "Shipping",
      component: loadView("ShippingPage"),
    },
    {
      path: "/shops/:id",
      component: loadView("Shop"),
      children: [
        {
          path: "",
          redirect: { name: "shopHome" },
        },
        {
          path: "details",
          name: "shopHome",
          component: loadView("GeneralPage"),
        },
        {
          path: "plans",
          name: "shopPlans",
          component: loadView("ShopPlansPage"),
        },
        {
          path: "settings",
          name: "shopSettings",
          component: loadView("SettingPage"),
        },
        {
          path: "locations",
          name: "shopLocations",
          component: loadView("LocationsPage"),
        },
        {
          path: "onboarding-modules",
          name: "shopOnboardingModules",
          component: loadView("OnboardingModulePage"),
        },
        {
          path: "features",
          name: "shopFeatures",
          component: loadView("FeaturesPage"),
        },
        {
          path: "taxonomy",
          name: "shopNewVariantTaxonomy",
          component: loadView("TaxonomyPage"),
        },
        {
          path: "users",
          name: "shopUsers",
          component: loadView("UserPage"),
        },
        {
          path: "shipping",
          name: "shopShipping",
          component: loadView("ShopShippingPage"),
        },
        {
          path: "reports",
          name: "shopReports",
          component: loadView("ReportsPage"),
        },
        {
          path: "updates",
          name: "shopUpdates",
          component: loadView("UpdatesPage"),
        },
        {
          path: "updates/return-policies/:policy_id",
          name: "updateLogs",
          component: loadView("UpdateLogsPage")
        },
        {
          path: "offset",
          name: "shopOffset",
          component: loadView("OffsetPage"),
        },
      ],
    },
    {
      path: "/install-links",
      name: "installLinks",
      component: loadView('InstallLinksPage')
    },
    {
      path: "/organizations",
      name: "organizations",
      component: loadView('Organizations')
    },
    {
      path: "/organization/:id",
      name: "organization",
      component: loadView('Organization')
    },
    {
      path: "/offset-billing",
      name: "offsetBilling",
      component: loadView('OffsetBillingPage')
    }
  ],
});


//enable scroll from scroll-lock on route change in case it was disabled as a blanket fix. Won't fix current page issues but should help with navigation.
router.afterEach(() => {
  enablePageScroll();
});

export default router;
